<template>
  <section class="common-section">
    <v-container>
      <!-- <h1 class="text-capitalize grey--text text--darken-2">
        {{ currentRouteName }}
      </h1> -->
      <transition>
        <router-view></router-view>
      </transition>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'Product',
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
</style>